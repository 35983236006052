import styled, { css } from "styled-components"

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}
const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
}

interface StorefrontPageContainerProps {
  backgoundColor?: string
}

export const StorefrontPageContainer = styled.div<StorefrontPageContainerProps>`
  min-height: 100vh;
  height: auto;
  padding: 0;
  background-color: ${props => props.backgoundColor || props.theme.lightergrey};
`

export const CenterWidthContainer = styled.div`
  max-width: 700px;
  margin: auto;
`

export const InfoBlock = styled.div`
  padding: 1rem 0.5rem;
  font-size: 1rem;
`

// categories

export const CategoryContainer = styled.div`
  margin: 0;
  overflow: hidden;
  width: 100%;
  padding: 0 var(--shopSpace);
  :nth-child(1) {
    margin-top: 2.5rem; // Shifts the UI down a bit, if no features products and categories are there.
  }
  @media ${device.laptop} {
    grid-template-columns: 1fr 50px 600px auto 1fr;
    grid-template-areas: ". tl tc tr .";
  }
`

interface CategoryHeaderProps {
  noBorder?: boolean
}

export const CategoryHeader = styled.div<CategoryHeaderProps>`
  //background-color: ${props => props.theme.lightergrey};
  //border-bottom: 1px solid ${props => props.theme.lightergrey};
  cursor: pointer;
  border-radius: 15px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0 0 -1px;
  padding: 1rem 0 0.5rem 0.2rem;
  grid-template-areas:
    "btn txt1"
    "btn txt2";
  ${props =>
    props.noBorder &&
    css`
      border: none;
    `}
`

export const CatMeta = styled.div`
  flex: 1;
`

export const CategoryTitle = styled.span`
  /* color: ${props => props.theme.primary}; */
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 1rem;

  grid-area: txt1;
  letter-spacing: 0.02em;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 1.2;
  font-weight: 600;
  hyphens: auto;
`
export const CategoryDescription = styled.span`
  display: flex;
  font-size: 0.8rem;
  grid-area: txt2;
  line-height: 1;
  margin: 0.2rem 0 0;
  opacity: 0.5;
  padding: 0 1rem 0 0;
`
export const CategoryArrowButton = styled.div`
  align-items: center;
  display: flex;
  grid-area: btn;
  justify-items: center;
  opacity: 0.3;
`

export const Header = styled.span`
  display: block;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  margin: 1.5rem 0 1rem;
  padding: 0;
`
export const Header2 = styled.span`
  display: block;
  font-size: 0.9rem;
  font-weight: normal;
  opacity: 0.7;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
`

// ITEMS IN PGs
export const ItemSelectorContainer = styled.div`
  /* border-bottom: 1px solid ${props => props.theme.greyBorder}; */
  padding: 0;
  display: grid;
  grid-gap: 0;
  align-items: center;
`

export const ItemTitleContainer = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
`
export const ItemTitle = styled.span`
  font-size: 1.2rem;
  line-height: 1.1;
  line-break: all;
`

export const ItemHeaderRight = styled.div`
  grid-area: right;
`

interface UnitSelectorToggleContainerProps {
  choices?: number
}

export const UnitSelectorToggleContainer = styled.div<UnitSelectorToggleContainerProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.choices || 3}, 1fr);
  align-items: center;
  border-radius: 10px;
  padding-top: 1rem;
  grid-area: header;
`

interface ToggleItemProps {
  active?: boolean
  disabled?: boolean
}

export const ToggleItem = styled.button<ToggleItemProps>`
  border: none;
  outline: none;
  padding: 0.25rem;
  cursor: pointer;
  height: 50px;
  margin: 1px;
  text-align: center;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
    filter: brightness(90%);
  }
  ${props =>
    props.active
      ? css`
          background-color: ${props.theme.darkergrey};
          color: white;
        `
      : css`
          background-color: ${props.theme.grey};
        `};
  ${props =>
    props.disabled &&
    css`
      opacity: 50%;
      cursor: not-allowed;
    `}
  &:first-child {
    border-radius: 10px 0 0 10px;
  }
  &:last-child {
    border-radius: 0 10px 10px 0;
  }
`

// Option Styling
export const OptionsContainer = styled.div`
  padding: 0.5rem 0 0.5rem 0;
`

interface OptionTitleProps {
  editable?: boolean
}

export const OptionTitle = styled.h5<OptionTitleProps>`
  color: currentColor;
  font-size: 1.2rem;
  font-weight: 400 !important;
  ${props =>
    props.editable &&
    css`
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    `}
`
export const OptionBox = styled.div`
  background-color: var(--white);
  padding: 1rem;
  border-radius: 15px;

  @media (min-width: 700px) {
    background-color: var(--white);
  }
`

interface SelectProps {
  margin?: string
  width?: string
  dense?: boolean
  red?: boolean
}

export const Select = styled.select<SelectProps>`
  appearance: none;
  background: white
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.grey};
  box-shadow: none;
  color: currentColor;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 300;
  height: 50px;
  margin: ${props => props.margin};
  outline: none;
  padding: 0.375rem 0.75rem;
  position: relative;
  transition:
    box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  width: ${props => (props.width && props.width) || "100%"};
  ${props =>
    props.dense &&
    css`
      padding: 0.5rem 0.25rem 0.25rem 0.25rem;
      height: unset;
    `};
  &:hover {
  }
  &:focus {
    border-color: ${props => props.theme.primary};
  }
  ${props =>
    props.red &&
    css`
      border: 1px solid ${props => props.theme.danger};
      &:focus {
        color: ${props => props.theme.danger};
        border-color: ${props => props.theme.danger};
      }
    `}
`

interface InfoTextProps {
  color?: string
  warning?: boolean
  success?: boolean
  danger?: boolean
  grey?: boolean
  darkgrey?: boolean
  size?: string
  margin?: string
}

// Texts
export const InfoText = styled.p<InfoTextProps>`
  color: ${props =>
    props.color ||
    (props.warning && props.theme.warning) ||
    (props.success && props.theme.success) ||
    (props.danger && props.theme.danger) ||
    (props.grey && props.theme.grey) ||
    (props.darkgrey && props.theme.darkgrey) ||
    props.theme.primary};
  font-size: ${props => props.size || "1rem"};
  margin: ${props => props.margin || "0"};
  & strong {
    font-weight: 600;
    font-size: 105%;
  }
`

export const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @media (min-width: 580px) {
    grid-template-columns: 1fr minmax(400px, 1fr);
  }
`

export const SummaryTable = styled.table`
  margin: 0 0 1.5rem;

  font-size: 1rem;
  width: 100%;
  max-width: 400px;
  background: var(--light);
  padding: 1rem;
  border-radius: 10px;

  tr {
    border-bottom: 1px solid var(--white);

    td {
      padding: 0.5rem 1rem;
    }
  }

  & tr:last-child {
    font-weight: bold;
  }
  & tr td:last-child {
    padding-left: 1rem;
    text-align: right;
  }
`
export const CollapsibleBody = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  align-items: baseline;
  @media (max-width: 1000px) {
    grid-template-columns: auto;
  }
`
// Editor
export const SettingUnit = styled.div`
  position: relative;
  padding-top: 1.5rem;
  margin: 0;
  & label {
    position: absolute;
    font-size: 1rem;
    left: 0;
    top: 1.5rem;
    transform: translateY(-50%) scale(0.9);
    background-color: white;
    color: ${props => props.theme.darkgrey};
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
  }
`

export const TextToggle = styled.p`
  cursor: pointer;
  color: currentColor;
  font-size: 0.75rem;
  color: ${props => props.theme.darkgrey};
  text-decoration: underline;
`
